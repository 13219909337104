import '../../App.css'
import { Link } from 'react-router-dom';
import { signal } from "@preact/signals-react";
export const eventId = signal("");

const MobileTableRow = ({ row, inplay }) => {
    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    return (
        <>

            <tr className="lg:hidden text-[1.08rem] border-[1px] border-[#c8ced3]">
                <td className='!pl-[6px] !pr-[6px] !py-[3px] max-w-[50vw]'>
                    <Link to={`/fullMarket/${row.event_id}/${row.is_inplay == 'True' ? "Inplay" : "Going Inplay"}`} className='flex flex-wrap gap-x-2 items-center'>
                        <span className='flex items-center gap-x-1 text-[#2789ce] font-bold text-[4vw]' >
                            {row?.event_name}
                        </span>
                        {row?.is_inplay == 'False' && (
                            <p className='text-[0.75rem] text-[#777777] font-normal'>
                                {formatDateString(row?.open_date)}
                            </p>
                        )}
                        {row?.is_inplay == 'True' && (
                            <span className='!text-[12px] font-bold in-play-effect'>In-Play</span>
                        )}
                    </Link>
                </td>
                <td className='!pl-[6px] !pr-[6px] !py-[3px]'>
                    <div className='flex items-center  justify-end gap-1'>
                        {
                            row?.event_type == 4 &&
                            row?.is_bm == 'True' &&
                            <span className='text-[4vw] px-[5px] py-px text-white bg-[linear-gradient(-180deg,#315195,#14213d)] pl-[2px] pr-[4px] py-[0] text-center italic font-bold rounded-[4px] leading-[15px] mr-[4px]'>
                                BM
                            </span>
                        }

                        {
                            row?.is_fancy == 'True' &&
                            <span className='text-[4vw] px-[5px] py-px text-white bg-[linear-gradient(180deg,_#0a92a5_15%,_#076875_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px] leading-[15px]'>
                                F
                            </span>
                        }

                        {row?.is_bm == 'True' &&
                            <span className='text-[4vw] px-[5px] py-px text-white bg-[linear-gradient(180deg,_#f26d1c_15%,_#d14100_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px] leading-[15px]'>
                                S
                            </span>
                        }
                    </div>
                </td>
                {!inplay && (
                    <td className="!py-[3px] text-center">
                        <a className='w-[6.66667vw] h-[6.66667vw] p-0 bg-no-repeat text-[11px] leading-[13px] block bg-contain text-center text-[#7e97a7] '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" color='#7e97a7' className='text-[11px] leading-[13px] text-center text-[#7e97a7]'>
                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path></svg>
                        </a>
                    </td>
                )}
            </tr>
        </>
    );
};

export default MobileTableRow;
