import '../../App.css'
import { useEffect, useState } from 'react';
import { Modal, Popover } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import SkyLoader from '../../components/sky-loader'
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import CasinoOldResults from "components/casinoOldResults";
import { getCasinoResults } from "../../redux/slice/casinoResults/casinoResultsSlice";
import { getCasinoCards } from "../../redux/slice/casinoCards/casinoCardsSlice";


export default function CARDS32A() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details
    const casioResults = useSelector((state) => state?.casinoResults?.casinoResults); // get casino results
    const casioCards = useSelector((state) => state?.casinoCards?.casinoCards); // get casino cards


    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');

    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [cardsCountState, setCardsCountState] = useState('');


    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setCasinoPosition] = useState([])
    const [result, setResult] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);

    const preDefineData = [
        {
            "marketId": "9222410071805",
            "marketName": "WINNER",
            "status": "SUSPEND",
            "min": 100,
            "max": 100000,
            "index": 0,
            "runners": [
                {
                    "selectionId": "123015008",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 12
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 14
                            }
                        ]
                    }
                },
                {
                    "selectionId": "123015009",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 5.85
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 6.55
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1230150010",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.15
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 3.55
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1230150011",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2.06
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 2.2
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "123015008": "Player 8",
                "123015009": "Player 9",
                "1230150010": "Player 10",
                "1230150011": "Player 11"
            }
        },
        {
            "marketId": "9222410071806",
            "marketName": "CARD COLOR",
            "status": "SUSPEND",
            "min": 100,
            "max": 100000,
            "index": 1,
            "runners": [
                {
                    "selectionId": "582597332503",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.25
                            }
                        ],
                        "lay": [
                            {
                                "size": 500000,
                                "price": 3.45
                            }
                        ]
                    }
                },
                {
                    "selectionId": "582597332504",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.25
                            }
                        ],
                        "lay": [
                            {
                                "size": 500000,
                                "price": 3.45
                            }
                        ]
                    }
                },
                {
                    "selectionId": "582597332505",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 2.44
                            }
                        ],
                        "lay": [
                            {
                                "size": 500000,
                                "price": 2.54
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "582597332503": "Any Three Card Black",
                "582597332504": "Any Three Card Red",
                "582597332505": "Two Black Two Red"
            }
        },
        {
            "marketId": "9222410071807",
            "marketName": "CARD TOTAL",
            "status": "SUSPEND",
            "min": 100,
            "max": 100000,
            "index": 2,
            "runners": [
                {
                    "selectionId": "5825915500",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 1.97
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825915501",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 1.97
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "5825915500": "8 & 9 Total",
                "5825915501": "10 & 11 Total"
            }
        },
        {
            "marketId": "9222410071808",
            "marketName": "LUCKY NUMBER",
            "min": 100,
            "status": "SUSPEND",
            "max": 100000,
            "index": 3,
            "runners": [
                {
                    "selectionId": "0",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "2",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "3",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "4",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "6",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "7",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "8",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "9",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 9.5
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9"
            }
        }
    ];

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])

    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoResults({}))
        }
    }, [])
    useEffect(() => {
        if (userInfo) {
          dispatch(getCasinoCards({}))
        }
      }, [])
    

    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://kingsws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['32c'],
                }
                // console.log('sendReq', sendReq);
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['32c']) {
                    let casinoss = casinos?.data;

                    if (casinoss) {

                        setResult(false);

                        if (casinoss?.skyPlaceBet.length > 0) {
                            if (casinoss?.skyPlaceBet[0]?.data.status) {
                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                if (casinoss?.skyPlaceBet[0]?.data?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.data?.marketArr)) {
                                    setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);

                                    if (!casinoss?.skyPlaceBet[0]?.data?.marketArr[0].runnersName) {
                                        setMarketState(preDefineData);
                                        setCasinoStatus("SUSPEND");
                                    }
                                }
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                // let marketdata = casinoss?.skyPlaceBet[0]?.data?.marketArr;

                                // if (marketdata && marketdata.length > 0) {
                                //     marketdata.map((market_type) => {
                                //     })
                                // }

                                if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                    setShowBetsSlip(false);
                                }
                            }

                        } else {
                            setMarketState(preDefineData);
                            setCasinoStatus("SUSPEND");
                        }

                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                setCardsCountState(casinoss?.skyCardScan[0].data?.cardCount);

                                // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)
                            }
                        }
                        // console.log('casinoss?.skyPlaceBet[0]?.eventId', casinoss?.skyPlaceBet[0]?.roundId);
                        // console.log('casinoss?.skyResultDeclared[0]?.eventId', casinoss?.skyResultDeclared[0]?.roundId);

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyResultDeclared[0]?.data?.resultsArr && casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {

                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                setTimeout(() => {
                                    setResult(true);
                                }, 4000)

                                let marketdata = casinoss?.skyPlaceBet[0]?.data?.marketArr;
                                // console.log('----- marketdata',marketdata);

                                if (marketdata && marketdata.length > 0) {
                                    marketdata.map((market_type) => {
                                        var MId = market_type.marketId;
                                        var runners = document.getElementsByClassName(
                                            "runners_" + MId
                                        );

                                        for (var item of runners) {
                                            var selecid = item.getAttribute("data-id");

                                            var elements = document.getElementsByClassName(
                                                `position_${MId}_${selecid}`
                                            );

                                            for (var el of elements) {
                                                el.innerHTML = "";
                                            }
                                        }
                                    });
                                }

                            }
                            else {
                                setWinnerName('');
                            }
                        }

                    }

                }


            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["32c"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            // if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //         `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //         el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //         el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            // } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            // }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }

    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            console.log({
                'selecid': selecid,
                'selectionId': selectionId,
                'market_name': market_name,
                'priceVal': priceVal,
                'stake': stake,
                'winloss': winloss,
                'isback': isback,
            });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }


    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        console.log({
            'event_id': event_id,
            'market_id': market_id,
            'is_back': is_back,
            'size': size,
            'is_fancy': is_fancy,
            'selection_id': selection_id,
            'runner_name': runner_name,
            'new_price': htmlId,
            'PM_FANCY': PM_FANCY,
            'market_name': market_name,
        })

        console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }


    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {

                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "32 Cards A",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['32c'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            console.log(error.message)
                            setIsLoading(false);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };


    return (
        <>
            {
                isLoading && <SkyLoader />
            }
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/other/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <CasinoVideo />
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-black text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>
                                <div className='flex flex-col'>
                                    <span className='leading-[5px] font-black mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffc107]'>Player 8: {cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsCountState.PLAYER_8 : 8} </span>
                                    <div className='flex'>
                                        <img className='w-[20px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_8[0] : '0'}.png`}></img>

                                    </div>
                                </div>
                                <div className='flex flex-col pt-[3px]'>
                                    <span className='leading-[5px] font-black mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffc107]'>Player 9: {cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsCountState?.PLAYER_9 : 9}</span>
                                    <div className='flex'>
                                        <img className='w-[20px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_9[0] : '0'}.png`}></img>

                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='leading-[5px] font-black mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffc107]'>Player 10: {cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsCountState?.PLAYER_10 : 10}</span>
                                    <div className='flex'>
                                        <img className='w-[20px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_10[0] : '0'}.png`}></img>

                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='leading-[5px] font-black mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffc107]'>Player 11: {cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsCountState?.PLAYER_11 : 11}</span>
                                    <div className='flex'>
                                        <img className='w-[20px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_11[0] : '0'}.png`}></img>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            winnerName &&
                            <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                    <span> {winnerName}</span>
                                </div>
                                <span className='absolute' >
                                    <canvas width="100vw" height="100vh">
                                    </canvas>
                                </span>
                            </div>
                        }


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid grid-cols-12'>
                            {
                                marketState?.length > 0 && marketState?.map((market, i) => {
                                    return (

                                        <div className='col-span-12 bg-[#fff] relative border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                            <span className='w-full py-[2px] px-[5px] text-[.75rem] font-black bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex items-center justify-between rounded-t-[.375rem]'>
                                                <span className="minmax" >
                                                    <strong >{market?.marketName}</strong>
                                                </span>
                                                <span className='block lg:hidden cursor-pointer'>
                                                    <Popover
                                                        placement="left"
                                                        content={() => <CustomContent market={market} />}
                                                        trigger="click"
                                                    >
                                                        <i className="fa fa-info-circle ml-2"></i>
                                                    </Popover>

                                                </span>
                                            </span>
                                            <div className='!p-0 flex-auto text-[.75rem] [word-wrap:break-word]'>
                                                <div className='block w-full overflow-x-hidden !relative text-[.75rem]'>
                                                    <table className='w-full relative mb-0 [caption-side:bottom] border-collapse text-[.75rem] text-[#212529] align-top border-[#dee2e6]'>
                                                        <tbody className='border-t-[1px_solid_#7e97a7] [vertical-align:inherit] text-[.75rem] [word-wrap:break-word]'>
                                                            {
                                                                casinoStatus == "SUSPEND" &&
                                                                <div className='p-[38px] absolute z-[1] flex items-center justify-center opacity-[.8] bg-[#fff] w-full h-full text-[30px] font-bold top-[0] text-[#ca1010] text-center border-[2px] border-[solid] border-[#ca1010]'>
                                                                    <span className='text-[1.875rem] font-bold text-[#ca1010] text-center uppercase'> SUSPENDED </span>
                                                                </div>
                                                            }

                                                            {
                                                                market?.runners?.map((runner, i) => {

                                                                    return (
                                                                        <tr className={`relative border-b-[1px] border-b-[#7e97a7] runners_${market.marketId}`} data-id={runner?.selectionId}>
                                                                            <td className='w-[60%] text-left pl-[6px] align-middle text-[.75rem] px-[0] py-[1.5px] font-black border-t-[none] bg-transparent border-b-[1px] border-b-[#7e97a7] [box-shadow:inset_0_0_0_9999px_transparent]'>
                                                                                <span className='text-[.75rem] block !font-black !text-[#23282c] text-left'>    {market?.runnersName[runner?.selectionId]}</span>
                                                                                <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                            </td>
                                                                            <td className={` align-middle text-center text-[12px] px-[0] py-[1.5px] font-black border-t-[none] relative bg-[#72bbef] [border-left-color:#fff] `} onClick={() =>
                                                                                handleOpenBetSlip(
                                                                                    casinoEventId,
                                                                                    casinoRoundId,
                                                                                    market.marketId,
                                                                                    true,
                                                                                    runner?.price?.back[0]?.size,
                                                                                    false,
                                                                                    runner.selectionId,
                                                                                    market?.runnersName[runner?.selectionId],
                                                                                    runner?.price?.back[0]?.price,
                                                                                    false,
                                                                                    market.marketName
                                                                                )
                                                                            }>
                                                                                <button className='[color:inherit] cursor-pointer text-center text-[.75rem] px-[0] py-[1.5px] font-black' >
                                                                                    <span span className='font-black block min-w-[50px] !text-[.75rem] !relative [color:inherit]' >  {runner?.price?.back[0]?.price}</span>
                                                                                    <span className='block font-normal text-[0.688rem] min-w-[50px] [color:inherit]'>{runner?.price?.back[0]?.size}</span>
                                                                                </button>
                                                                            </td>
                                                                            {
                                                                                runner?.price?.lay &&

                                                                                <td className={` align-middle text-center text-[12px] px-[0] py-[1.5px] font-black border-t-[none] relative bg-[#f9c9d4] [border-left-color:#fff] `} onClick={() =>
                                                                                    handleOpenBetSlip(
                                                                                        casinoEventId,
                                                                                        casinoRoundId,
                                                                                        market.marketId,
                                                                                        true,
                                                                                        runner?.price?.lay[0]?.size,
                                                                                        false,
                                                                                        runner.selectionId,
                                                                                        market?.runnersName[runner?.selectionId],
                                                                                        runner?.price?.lay[0]?.price,
                                                                                        false,
                                                                                        market.marketName
                                                                                    )
                                                                                }>
                                                                                    <button className='[color:inherit] cursor-pointer text-center text-[.75rem] px-[0] py-[1.5px] font-black' >
                                                                                        <span className='font-black block min-w-[50px] !text-[.75rem] !relative [color:inherit]'>{runner?.price?.lay[0]?.price}</span>
                                                                                        <span className='block font-normal text-[0.688rem] min-w-[50px] [color:inherit]'>{runner?.price?.lay[0]?.size}</span>
                                                                                    </button>
                                                                                </td>
                                                                            }
                                                                            <td className='w-[0px] lg:w-[15%]'>

                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }



                                                        </tbody>


                                                    </table>

                                                    {showBetsSlip && market_id == market.marketId && (
                                                        <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />
                                                    )}
                                                </div>
                                            </div>

                                        </div>


                                    )
                                })
                            }

                            {
                                (casioResults && casioResults?.skyCards32AResultDeclared) &&
                                <CasinoOldResults event_type={Appconfig.casino_type_id["32c"]} results={casioResults?.skyCards32AResultDeclared} cards={casioCards.skyCards32ACardDeclared} />
                            }
                        </div>
                    </div>

                </div >
            }
        </>
    )
}
