import '../../App.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import SkyLoader from 'components/sky-loader';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import CasinoOldResults from "components/casinoOldResults";
import { getCasinoResults } from "../../redux/slice/casinoResults/casinoResultsSlice";
import { getCasinoCards } from "../../redux/slice/casinoCards/casinoCardsSlice";


export default function ANDARBAHARA() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details
    const casioResults = useSelector((state) => state?.casinoResults?.casinoResults); // get casino results
    const casioCards = useSelector((state) => state?.casinoCards?.casinoCards); // get casino cards


    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [cardsResultState, setResultCardsState] = useState('');


    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')
    const [result, setResult] = useState(false);


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setCasinoPosition] = useState([])

    const preDefineData = [
        {
            "marketId": "9251910065345",
            "marketName": "WINNER",
            "min": 100,
            "max": 500000,
            "index": 0,
            "runners": [
                {
                    "selectionId": "1782381",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1782382",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1782385",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1782386",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "1782381": "1st BET A",
                "1782382": "1st BET B",
                "1782385": "2st BET A",
                "1782386": "2st BET B"
            }
        },
        {
            "marketId": "9251910065346",
            "marketName": "SIDE BET",
            "min": 100,
            "max": 100000,
            "index": 1,
            "runners": [
                {
                    "selectionId": "1782383",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "1782384",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "1782383": "SIDE BET A",
                "1782384": "SIDE BET B"
            }
        },
        {
            "marketId": "9251910065347",
            "marketName": "ODD/EVEN",
            "min": 100,
            "max": 100000,
            "index": 2,
            "runners": [
                {
                    "selectionId": "995566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 1.83
                            }
                        ]
                    }
                },
                {
                    "selectionId": "665566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 2.12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "665566": "EVEN",
                "995566": "ODD"
            }
        },
        {
            "marketId": "9251910065348",
            "marketName": "CARD SUIT",
            "min": 100,
            "max": 100000,
            "index": 3,
            "runners": [
                {
                    "selectionId": "58259772337",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.7
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259432787",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.7
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825934266637",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.7
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825925827",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 3.7
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "58259772337": "SPADES",
                "58259432787": "HEARTS",
                "5825934266637": "DIAMONDS",
                "5825925827": "CLUBS"
            }
        },
        {
            "marketId": "9251910065349",
            "marketName": "LUCKY CARD",
            "min": 100,
            "max": 100000,
            "index": 4,
            "runners": [
                {
                    "selectionId": "5825901",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825902",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825903",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825904",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825905",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825906",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825907",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825908",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825909",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259010",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259011",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259012",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259013",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "5825901": "A",
                "5825902": "2",
                "5825903": "3",
                "5825904": "4",
                "5825905": "5",
                "5825906": "6",
                "5825907": "7",
                "5825908": "8",
                "5825909": "9",
                "58259010": "10",
                "58259011": "J",
                "58259012": "Q",
                "58259013": "K"
            }
        }
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })


    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoResults({}))
        }
    }, [])
    useEffect(() => {
        if (userInfo) {
          dispatch(getCasinoCards({}))
        }
      }, [])
    

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])

    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);


    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://kingsws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['ab'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                if (message?.data) {
                    const casinos = JSON.parse(message?.data);

                    if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['ab']) {
                        let casinoss = casinos?.data;


                        if (casinoss) {
                            setResult(false);
                            if (casinoss?.skyPlaceBet.length > 0) {

                                if (casinoss?.skyPlaceBet[0]?.data.status) {

                                    if (casinoss?.skyPlaceBet[0]?.data?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.data?.marketArr)) {
                                        setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);
                                    } else {
                                        setMarketState(preDefineData);
                                    }
                                    setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                    setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                    setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                    if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                        setShowBetsSlip(false);
                                    }
                                }
                            } else {
                                setMarketState(preDefineData);
                                setCasinoStatus("SUSPEND");
                            }


                            if (casinoss?.skyCardScan && casinoss?.skyCardScan.length > 0) {
                                if (casinoss?.skyCardScan[0]?.data?.cardsArr || casinoss?.skyCardScan[0].data?.cardScan) {
                                    if (casinoss?.skyCardScan[0]?.data?.cardScan) {
                                        setResultCardsState(casinoss?.skyCardScan[0].data?.cardScan);
                                    } else {
                                        setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);

                                    }
                                    // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                    setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)

                                }
                            }

                            if (casinoss?.skyResultDeclared) {
                                if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                    setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                    getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                    setTimeout(() => {
                                        setResult(true);
                                    }, 4000)
                                } else {
                                    setWinnerName('');
                                }
                            }

                        }

                    }

                }

            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["ab"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            // if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //         `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //         el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //         el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            // } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            // }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }



    const handlePriceValue = (priceVal) => {
        if (String(priceVal).startsWith("NaN")) {
            priceVal = String(priceVal).replace("NaN", "");
        }
        priceVal = parseFloat(priceVal).toFixed(2);
        setBetPlaceData({
            ...BetPlaceData,
            price: parseFloat(priceVal).toFixed(2)
        });
        calc(StakeValue, priceVal, BetPlaceData.selection_id)
        SetPosition(StakeValue, priceVal, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };

    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            // console.log({
            //     'selecid': selecid,
            //     'selectionId': selectionId,
            //     'market_name': market_name,
            //     'priceVal': priceVal,
            //     'stake': stake,
            //     'winloss': winloss,
            //     'isback': isback,
            // });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }


    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        console.log({
            'event_id': event_id,
            'market_id': market_id,
            'is_back': is_back,
            'size': size,
            'is_fancy': is_fancy,
            'selection_id': selection_id,
            'runner_name': runner_name,
            'new_price': htmlId,
            'PM_FANCY': PM_FANCY,
            'market_name': market_name,
        })

        // console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }




    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "Andar Bahar A",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['ab'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    // console.log('response:', response.data.resultMessage)
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            console.log(error.message)
                            setIsLoading(true);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };


    return (
        <>
            {
                isLoading && <SkyLoader />
            }

            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/other/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <CasinoVideo />
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>

                                <div className='mt-[1rem] flex flex-wrap items-center'>
                                    <div className='flex-[0_0_auto] w-[8.33%]'>
                                        <div className='flex flex-wrap'>
                                            <div className='w-full'>
                                                <p className='mb-[0px] text-white'><b className='relative top-[-10px]'>A</b></p>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <div className='w-full'>
                                                <p className='mb-[0px] text-white'><b className='relative top-[10px]'>B</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pl-[.25rem] flex-[0_0_auto] w-[18.66%]'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsResultState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsResultState?.RESULT : '0'}.png`}></img>
                                    </div>

                                    <div className='flex flex-col ml-5'>
                                        <div className='flex flex-col'>
                                            <div className='flex'>
                                                {/* {
                                                    cardsState?.ANDAR_CARDS?.length > 0 &&
                                                    <>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.ANDAR_CARDS[0] : '0'}.png`}></img>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.ANDAR_CARDS[1] : '0'}.png`}></img>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.ANDAR_CARDS[2] : '0'}.png`}></img>
                                                    </>
                                                } */}

                                                {
                                                    cardsState?.ANDAR_CARDS?.length > 0 &&
                                                    <>
                                                        {cardsState?.ANDAR_CARDS.slice(-3).map((card, index) => (
                                                            <img
                                                                key={index}
                                                                className='w-[23px] h-auto mr-[3px]'
                                                                src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId === casinoRoundId ? card : '0'}.png`}
                                                            />
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className='flex flex-col pt-[8px]'>
                                            <div className='flex'>
                                                {/* {
                                                    cardsState?.BAHAR_CARDS?.length > 0 &&
                                                    <>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.BAHAR_CARDS[0] : '0'}.png`}></img>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.BAHAR_CARDS[1] : '0'}.png`}></img>
                                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.BAHAR_CARDS[2] : '0'}.png`}></img>
                                                    </>
                                                } */}
                                                {
                                                    cardsState?.BAHAR_CARDS?.length > 0 &&
                                                    <>
                                                        {cardsState?.BAHAR_CARDS.slice(-3).map((card, index) => (
                                                            <img
                                                                key={index}
                                                                className='w-[23px] h-auto mr-[3px]'
                                                                src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId === casinoRoundId ? card : '0'}.png`}
                                                            />
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            winnerName &&
                            <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                    <span> {winnerName}</span>
                                </div>
                                <span className='absolute' >
                                    <canvas width="100vw" height="100vh">
                                    </canvas>
                                </span>
                            </div>
                        }


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid grid-cols-12'>
                            <div className='col-span-12 w-[100%] py-[5px]  bg-[#ddd] text-[#000] text-[.75rem]'>
                                <div className='grid grid-cols-12 w-full'>
                                    <div className='col-span-12 lg:col-span-6 flex justify-center '>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>A</span>

                                        {
                                            marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                market?.marketName === "SIDE BET" && market?.runners && market?.runners?.length > 0 && market?.runners?.map((runner, i) => {
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                    return (
                                                        market?.runnersName[runner?.selectionId] == "SIDE BET A" &&
                                                        <div className='w-[20%] ml-[10px]'>
                                                            <div className={`flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price relative runners_${market.marketId}  ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                    casinoEventId,
                                                                    casinoRoundId,
                                                                    market.marketId,
                                                                    true,
                                                                    runner?.price?.back[0]?.size,
                                                                    false,
                                                                    runner.selectionId,
                                                                    market?.runnersName[runner?.selectionId],
                                                                    runner?.price?.back[0]?.price,
                                                                    false,
                                                                    market.marketName
                                                                ) : null}>
                                                                <span> {market?.runnersName[runner?.selectionId]} </span>
                                                                <span className='mt-[.25rem]'> {runner.price.back[0].price} </span>
                                                            </div>
                                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                                <span className='extra-pf'>
                                                                    <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>
                                                                </span>

                                                            </div>
                                                        </div>

                                                    )

                                                })
                                            )
                                            )
                                        }


                                        {
                                            marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                market?.marketName === "WINNER" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => {
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                    return (
                                                        (market?.runnersName[runner?.selectionId] == "1st BET A") || (market?.runnersName[runner?.selectionId] == "2st BET A") ?
                                                            <div className='first-bet w-[25%] mx-[10px]'>
                                                                <div className={`flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price relative bg-[#086cb8] text-[#fff] runners_${market.marketId} ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                    onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                        casinoEventId,
                                                                        casinoRoundId,
                                                                        market.marketId,
                                                                        true,
                                                                        runner?.price?.back[0]?.size,
                                                                        false,
                                                                        runner.selectionId,
                                                                        market?.runnersName[runner?.selectionId],
                                                                        runner?.price?.back[0]?.price,
                                                                        false,
                                                                        market.marketName
                                                                    ) : null}>
                                                                    <span>  {market?.runnersName[runner?.selectionId]}  </span>
                                                                    <span className='mt-[.25rem]'> {runner.price.back[0].price} </span>
                                                                </div>
                                                                <div className='book text-center mt-[5px] text-[#000]'>
                                                                    <span className='extra-pf'>
                                                                        <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>


                                                                    </span>

                                                                </div>
                                                            </div>
                                                            :
                                                            <></>

                                                    )

                                                })
                                            )
                                            )
                                        }

                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>A</span>
                                    </div>




                                    <div className='col-span-12 lg:col-span-6 flex justify-center '>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>B</span>

                                        {
                                            marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                market?.marketName === "SIDE BET" && market?.runners && market?.runners?.length > 0 && market?.runners?.map((runner, i) => {
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                    return (
                                                        market?.runnersName[runner?.selectionId] == "SIDE BET B" &&
                                                        <div className='w-[20%] ml-[10px]'>
                                                            <div className={`flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price relative runners_${market.marketId} ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                    casinoEventId,
                                                                    casinoRoundId,
                                                                    market.marketId,
                                                                    true,
                                                                    runner?.price?.back[0]?.size,
                                                                    false,
                                                                    runner.selectionId,
                                                                    market?.runnersName[runner?.selectionId],
                                                                    runner?.price?.back[0]?.price,
                                                                    false,
                                                                    market.marketName
                                                                ) : null}>
                                                                <span> {market?.runnersName[runner?.selectionId]} </span>
                                                                <span className='mt-[.25rem]'> {runner.price.back[0].price} </span>
                                                            </div>
                                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                                <span className='extra-pf'>
                                                                    <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>

                                                                </span>

                                                            </div>
                                                        </div>

                                                    )

                                                })
                                            )
                                            )
                                        }


                                        {
                                            marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                market?.marketName === "WINNER" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => {
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                    return (
                                                        (market?.runnersName[runner?.selectionId] == "1st BET B") || (market?.runnersName[runner?.selectionId] == "2st BET B") ?
                                                            <div className='first-bet w-[25%] mx-[10px]'>
                                                                <div className={`flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price relative bg-[#086cb8] text-[#fff] runners_${market.marketId} ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                    onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                        casinoEventId,
                                                                        casinoRoundId,
                                                                        market.marketId,
                                                                        true,
                                                                        runner?.price?.back[0]?.size,
                                                                        false,
                                                                        runner.selectionId,
                                                                        market?.runnersName[runner?.selectionId],
                                                                        runner?.price?.back[0]?.price,
                                                                        false,
                                                                        market.marketName
                                                                    ) : null}>
                                                                    <span>  {market?.runnersName[runner?.selectionId]}  </span>
                                                                    <span className='mt-[.25rem]'> {runner.price.back[0].price} </span>
                                                                </div>
                                                                <div className='book text-center mt-[5px] text-[#000]'>
                                                                    <span className='extra-pf'>
                                                                        <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>

                                                                    </span>

                                                                </div>
                                                            </div>

                                                            :
                                                            <></>
                                                    )

                                                })
                                            )
                                            )
                                        }

                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>B</span>

                                    </div>



                                </div>

                                {
                                    showBetsSlip &&
                                    market_id &&
                                    (BetPlaceData.runner_name === "SIDE BET A" || BetPlaceData.runner_name === "SIDE BET B" || BetPlaceData.runner_name === "1st BET A" || BetPlaceData.runner_name === "1st BET B" || BetPlaceData.runner_name === "2st BET A" || BetPlaceData.runner_name === "2st BET B") && (
                                        <CasinoBetPlace
                                            type={type}
                                            BetPlaceData={BetPlaceData}
                                            placeStakeValue={placeStakeValue}
                                            StakeValue={StakeValue}
                                            chips={chips}
                                            cancelBetSlip={cancelBetSlip}
                                            placing={placing}
                                            betPlace={betPlace}
                                        />
                                    )
                                }


                            </div>


                            <div className='col-span-12 w-[100%] justify-between text-[#000] text-[14px] mt-[.5rem]'>
                                <div className='grid grid-cols-12 gap-x-[.5rem] w-full'>
                                    <div className='col-span-12 lg:col-span-6 relative px-[15px] bg-[#ddd] text-[#000] p-[5px]'>
                                        <div className='grid grid-cols-12'>

                                            {
                                                marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                    market?.marketName === "ODD/EVEN" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => {
                                                        const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                        return (
                                                            <div className='col-span-6 text-center px-[15px] relative'>
                                                                <div className='text-[20px] h-[35px] '>
                                                                    <b> {market?.runnersName[runner?.selectionId]} </b>
                                                                </div>
                                                                <div className={`font-bold text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price runners_${market.marketId} ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                    onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                        casinoEventId,
                                                                        casinoRoundId,
                                                                        market.marketId,
                                                                        true,
                                                                        runner?.price?.back[0]?.size,
                                                                        false,
                                                                        runner.selectionId,
                                                                        market?.runnersName[runner?.selectionId],
                                                                        runner?.price?.back[0]?.price,
                                                                        false,
                                                                        market.marketName
                                                                    ) : null}>
                                                                    <span className="odd text-[18px] font-bold text-[#000]">  {runner.price.back[0].price} </span>
                                                                </div>
                                                                <div className='mt-[.25rem]'>
                                                                    <span className='extra-pf'>
                                                                        <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>

                                                                    </span>

                                                                </div>
                                                            </div>

                                                        )

                                                    })
                                                )
                                                )
                                            }

                                        </div>

                                    </div>
                                    <div className='col-span-12 lg:col-span-6 relative px-[15px] bg-[#ddd] text-[#000] p-[5px]'>
                                        <div className='grid grid-cols-12'>

                                            {
                                                marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                    market?.marketName === "CARD SUIT" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => {
                                                        const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                        return (

                                                            <div className='col-span-3 relative px-[15px] text-center'>
                                                                <div className='text-[20px] h-[35px] flex justify-center'>
                                                                    {
                                                                        market?.runnersName[runner?.selectionId] == "SPADES" ?

                                                                            <img src='/andarbahar/SPADES.webp' className='h-[35px] align-middle' />
                                                                            : market?.runnersName[runner?.selectionId] == "HEARTS" ?
                                                                                <img src='/andarbahar/HEARTS.webp' className='h-[35px] align-middle' />
                                                                                : market?.runnersName[runner?.selectionId] == "DIAMONDS" ?
                                                                                    <img src='/andarbahar/DIAMONDS.webp' className='h-[35px] align-middle' />
                                                                                    : market?.runnersName[runner?.selectionId] == "CLUBS" ?
                                                                                        <img src='/andarbahar/CLUBS.webp' className='h-[35px] align-middle' />
                                                                                        :
                                                                                        <></>

                                                                    }
                                                                </div>
                                                                <div className={`font-black text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price runners_${market.marketId} ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                    casinoEventId,
                                                                    casinoRoundId,
                                                                    market.marketId,
                                                                    true,
                                                                    runner?.price?.back[0]?.size,
                                                                    false,
                                                                    runner.selectionId,
                                                                    market?.runnersName[runner?.selectionId],
                                                                    runner?.price?.back[0]?.price,
                                                                    false,
                                                                    market.marketName
                                                                ) : null}>
                                                                    <span className="odd">  {runner.price.back[0].price} </span>
                                                                </div>
                                                                <div className='mt-[.25rem]'>
                                                                    <span className='extra-pf'>
                                                                        <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>

                                                                    </span>

                                                                </div>
                                                            </div>



                                                        )

                                                    })
                                                )
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>

                                {
                                    showBetsSlip &&
                                    market_id &&
                                    (BetPlaceData.market_name === "ODD/EVEN" || BetPlaceData.market_name === "CARD SUIT") && (
                                        <CasinoBetPlace
                                            type={type}
                                            BetPlaceData={BetPlaceData}
                                            placeStakeValue={placeStakeValue}
                                            StakeValue={StakeValue}
                                            chips={chips}
                                            cancelBetSlip={cancelBetSlip}
                                            placing={placing}
                                            betPlace={betPlace}
                                        />
                                    )
                                }


                            </div>

                            <div className='col-span-12 w-[100%] bg-[#ddd] text-[#000] p-[5px] mt-[.5rem]'>
                                <div className='w-full relative px-[10px] text-center'>
                                    <span className='text-[18px]'>12</span>
                                </div>
                                <div className='w-full relative px-[10px] text-center'>

                                    <div className='-ml-[5px] -mr-[5px] mt-[.5rem] flex flex-wrap w-[100%]'>
                                        <div className='w-[100%] text-center pr-[calc(1.5rem*.2)] pl-[calc(1.5rem*0)]'>
                                            {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                market?.marketName === "LUCKY CARD" && market?.runners && market?.runners.length && market.runners.map((runner, i) => {
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                    return (
                                                        <div className={`mr-[10px] inline-block runners_${market.marketId}  `} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                            casinoEventId,
                                                            casinoRoundId,
                                                            market.marketId,
                                                            true,
                                                            runner?.price?.back[0]?.size,
                                                            false,
                                                            runner.selectionId,
                                                            market?.runnersName[runner?.selectionId],
                                                            runner?.price?.back[0]?.price,
                                                            false,
                                                            market.marketName
                                                        ) : null}>
                                                            <div className={`overflow-hidden ${isSuspended ? 'suspended' : ''}`}>
                                                                <img className='w-[40px]' src={`https://casino.kingexch9.com/assets/t10cards/${market?.runnersName[runner?.selectionId]}.jpeg`}></img>
                                                            </div>
                                                            <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>
                                                        </div>
                                                    )
                                                })))}
                                        </div>
                                    </div>


                                </div>

                                {
                                    showBetsSlip &&
                                    market_id &&
                                    (BetPlaceData.market_name === "LUCKY CARD") && (
                                        <CasinoBetPlace
                                            type={type}
                                            BetPlaceData={BetPlaceData}
                                            placeStakeValue={placeStakeValue}
                                            StakeValue={StakeValue}
                                            chips={chips}
                                            cancelBetSlip={cancelBetSlip}
                                            placing={placing}
                                            betPlace={betPlace}
                                        />
                                    )
                                }



                            </div>

                            {
                                (casioResults && casioResults?.andarBaharResultDeclared) &&
                                <CasinoOldResults event_type={Appconfig.casino_type_id["ab"]} results={casioResults?.andarBaharResultDeclared} cards={casioCards.andarBaharCardDeclared} />
                            }


                        </div>
                    </div>

                </div >
            }
        </>
    )
}
